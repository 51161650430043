import React from "react";
import CustomerViews from "../components/CustomerViews/CustomerViews";
import Layout from "../components/Layout/Layout";
import Discount from "../components/Discount/Discount";
import HappyFaces from "../components/HappyFaces/HappyFaces";
import HeroSecond from "../components/HeroSecond/HeroSecond";
import Seo from "../components/Seo";
import Data from "../utils/data";

const BuyFollowers = () => {
  const followersQuery = Data();

  return (
    <>
      <Seo
        title={followersQuery.strapiFollowersPage.followersPageSeo.title}
        description={
          followersQuery.strapiFollowersPage.followersPageSeo.description
        }
        canonical="/buy-instagram-followers"
        keywords="buy instaggram followers"
      />
      <Layout>
        <HeroSecond
          title={
            followersQuery.strapiFollowersPage.followersHeroSection.heading
          }
          description={
            followersQuery.strapiFollowersPage.followersHeroSection.description
          }
          image={
            followersQuery.strapiFollowersPage.followersHeroSection.heroImage
              .localFile.childImageSharp.gatsbyImageData
          }
          brand={followersQuery.strapiLikePage.brandImages}
        />
        <Discount from="followers" />
        <HappyFaces />
        <CustomerViews />
      </Layout>
    </>
  );
};
export default BuyFollowers;
